import React, { Component } from 'react'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import './Admin.css'

//////////////

export default class Admin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            _isLoggedIn: false,

            addressInput: "",
            priceInput: "",
            slugInput: "",
            descInput: "",
            neighbourhoodInput: "",
            sizeInput: "",
            agreementInput: "",
            moveInput: "",
            parkingInput: "",
            petInput: "",
            outdoorInput: "",
            smokingInput: "",
            utilitiesInput: "",
            wifiInput: "",
            furnishedInput: "",
            acInput: "",
            typeInput: "",
            bedInput: "",
            bathInput: "",

            uploadMsg: "",
            images: []
        }
    }

    async componentDidMount() {

        await firebase.auth().onAuthStateChanged(async (user) => {
            if(user) {
                await this.setState({ _isLoggedIn: true })
            } else {
                await this.setState({ _isLoggedIn: false })
            }
        })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    login = async (e) => {  //passes the password and email input to the firebase auth
        e.preventDefault()
        
        await firebase.auth().signInWithEmailAndPassword(this.state.usernameInput, this.state.passwordInput).then(() => {
            alert("success")
        })
        .catch((err) => {
            alert(err.code + err.message)
        })
    }

    logout = async () => {
        await firebase.auth().signOut().then()
          .catch((err) => {
            alert(err)
          });
    }

    handlePhotos = (e) => {
        // adding the images to state only if the user actually added at least one
        if(e.target.files[0]) {
            let images = []
            for(const i of e.target.files) {
                images.push(i)
            }
            this.setState({ images })
        }
    }

    addProp = async (e) => {
        e.preventDefault()

        let propObj = {
            address: this.state.addressInput,
            price: this.state.priceInput,
            unitId: this.state.slugInput,
            desc: this.state.descInput,
            rented: false,
            info: {
                type: this.state.typeInput,
                unitSize: this.state.sizeInput,
                agreementType: this.state.agreementInput,
                moveDate: this.state.moveInput,
                parking: this.state.parkingInput,
                petFriendly: this.state.petInput,
                outdoorSpace: this.state.outdoorInput,
                smoking: this.state.smokingInput,
                utilities: this.state.utilitiesInput,
                wifi: this.state.wifiInput,
                furnished: this.state.furnishedInput,
                ac: this.state.acInput,
            },
            tags: {
                neighbourhood: this.state.neighbourhoodInput,
                bed: this.state.bedInput,
                bath: this.state.bathInput,
            }
        }

        // uploading the images
        for(const i of this.state.images) {
            let imagesRef = firebase.storage().ref().child(`images/${this.state.slugInput}/${i.name}`);
            let uploadTask = imagesRef.put(i)

            await uploadTask.on("state_changed", function(snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                this.setState({ uploadMsg: `Uploading... ${progress}%` })
            }.bind(this), function(err) {
                this.setState({ uploadMsg: "There was an error uploading the images." })
            }.bind(this), function() {
                this.setState({ uploadMsg: "Images successfully uploaded." })

                // creating the firestore doc
                try {
                    firebase.firestore().collection("rentalListings").doc(this.state.slugInput).set(propObj)
                    .then(() => {
                        alert("Doc successfully written!")
                    })
                    .catch((err) => {
                        alert(err)
                    })
                } catch(err) {
                    alert(err)
                }

            }.bind(this))

        }
        
    }
      

    render() {

        if(this.state._isLoggedIn) {

            return(
                <body className="admin-body">
                    <div className="admin-container">
                        <button onClick={this.logout.bind(this)} className="logout-button">LOGOUT</button>

                        <h1 className="add-prop-title">ADD NEW PROPERTY</h1>
                        <form className="admin-prop-form">

                            <div className="admin-input-item-container">
                                <label htmlFor="address" className="admin-label">Address</label>
                                <input type="text" id="address" name="addressInput" className="admin-input" placeholder="67 Gleeson" value={this.state.addressInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="price" className="admin-label">Price</label>
                                <input type="text" id="price" name="priceInput" className="admin-input" placeholder="$1200 / mo" value={this.state.priceInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="slug" className="admin-label">Slug (this is the url)</label>
                                <input type="text" id="slug" name="slugInput" className="admin-input" placeholder="67-gleeson" value={this.state.slugInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="type" className="admin-label">Type of Property (Appartment, semi-detached, etc.)</label>
                                <input type="text" id="type" name="typeInput" className="admin-input"  placeholder="Townhouse" value={this.state.typeInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="neighbourhood" className="admin-label">Neighbourhood</label>
                                <input type="text" id="neighbourhood" name="neighbourhoodInput" className="admin-input" placeholder="Barrhaven" value={this.state.neighbourhoodInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="bed" className="admin-label"># of Bedrooms</label>
                                <input type="text" id="bed" name="bedInput" className="admin-input" placeholder="#" value={this.state.bedInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="bath" className="admin-label"># of Bathrooms</label>
                                <input type="text" id="bath" name="bathInput" className="admin-input" placeholder="#" value={this.state.bathInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="size" className="admin-label">Size of Unit</label>
                                <input type="text" id="size" name="sizeInput" className="admin-input" placeholder="5000 sqft" value={this.state.sizeInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="agreement" className="admin-label">Agreement Type</label>
                                <input type="text" id="agreement" name="agreementInput" className="admin-input" placeholder="1yr lease" value={this.state.agreementInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="move" className="admin-label">Move Date</label>
                                <input type="text" id="move" name="moveInput" className="admin-input" placeholder="Sep 12, 1969" value={this.state.moveInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="parking" className="admin-label">Parking</label>
                                <input type="text" id="parking" name="parkingInput" className="admin-input" placeholder="# or None" value={this.state.parkingInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="pet" className="admin-label">Pet Friendly</label>
                                <input type="text" id="pet" name="petInput" className="admin-input" placeholder="Yes/No/Cats/Dogs" value={this.state.petInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="outdoor" className="admin-label">Outdoor Space</label>
                                <input type="text" id="outdoor" name="outdoorInput" className="admin-input" placeholder="Yes/No" value={this.state.outdoorInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="smoking" className="admin-label">Smoking</label>
                                <input type="text" id="smoking" name="smokingInput" className="admin-input" placeholder="Outside" value={this.state.smokingInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="utilities" className="admin-label">Utilities</label>
                                <input type="text" id="utilities" name="utilitiesInput" className="admin-input" placeholder="Heat, Water, Hydro" value={this.state.utilitiesInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="wifi" className="admin-label">Wifi</label>
                                <input type="text" id="wifi" name="wifiInput" className="admin-input" placeholder="Yes/No" value={this.state.wifiInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="furnished" className="admin-label">Furnished</label>
                                <input type="text" id="furnished" name="furnishedInput" className="admin-input" placeholder="Yes/No" value={this.state.furnishedInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="ac" className="admin-label">AC</label>
                                <input type="text" id="ac" name="acInput" className="admin-input" placeholder="Yes/No" value={this.state.acInput || ""} onChange={this.handleChange.bind(this)}></input>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="desc" className="admin-label">Description</label>
                                <textarea type="text" id="desc" name="descInput" className="admin-input admin-desc" placeholder="Pararaph format, not point form." value={this.state.descInput || ""} onChange={this.handleChange.bind(this)}></textarea>
                            </div>

                            <div className="admin-input-item-container">
                                <label htmlFor="photos" className="admin-label">Add Photos</label>
                                <input type="file" id="photos" className="admin-input" multiple onChange={this.handlePhotos.bind(this)}></input>
                            <p>{this.state.uploadMsg}</p>
                            </div>

                        
                        </form>
                        
                        <button onClick={this.addProp.bind(this)} className="admin-prop-add-button">ADD</button>
                    
                    </div>
                </body>
            
               
            
            )

         } else {

            return(
                          
                <div className="admin-container">
                    <form className="admin-login-form">
                        <label htmlFor="admin-username">Username</label>
                        <input type="text" id="admin-username" name="usernameInput" className="admin-input" value={this.state.usernameInput || ""} onChange={this.handleChange.bind(this)}></input>

                        <label htmlFor="admin-pw">Password</label>
                        <input type="password" id="admin-pw" name="passwordInput" className="admin-input" value={this.state.passwordInput || ""} onChange={this.handleChange.bind(this)}></input>

                        <button onClick={this.login.bind(this)} className="login-button">LOGIN</button>
                    </form>
                </div>
                   
            )

         }

    }

}