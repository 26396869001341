import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Nav from './Nav'

//////////////

export default class Submitted extends Component {

    constructor(props) {
        super(props)
        this.state = {
           
        }
    }

    render() {

        return(

            <div>
                <Nav />

                <div className="container">
                    <div className="submit-container">
                        <h1 className="submit-header">Submitted!</h1>
                        <p className="submit-message">Your request has been sent.</p>
                        <Link to="/" className="button submitted-button">HOME</Link>
                    </div>

                </div>
            </div>
            
        )
    }

}