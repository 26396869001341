import React from 'react'

import nav_logo from '../../img/nav_logo.png'
import nav_text from '../../img/nav_text.png'

import './HamburgerButton.css'

//////////////

const HamburgerButton = props => (
    <div className="mobile-navbar-container">
        <div className="hamburger-button-content">
            <div class="left flex">
                <img src={nav_logo} alt="logo" className="mobile-nav-logo" />
            </div>
            <div class="middle flex">
                <img src={nav_text} alt="logo text" className="mobile-nav-logo-text" />
            </div>
            <div class="right flex">
                <div className="hamburger-button-container">
                    <button className="hamburger-button" onClick={props.clickHandler}>
                        <div className="hamburger-button-line" />
                        <div className="hamburger-button-line" />
                        <div className="hamburger-button-line" />
                    </button>
                </div>
            </div>
        </div>
    </div>
)

export default HamburgerButton