import * as firebase from 'firebase'
import 'firebase/database'
import 'firebase/storage'

//////////////

export default class GetData {
    
    static async getAllUnits() {
        let units = []
        
        await firebase.firestore().collection("rentalListings").orderBy("rented").get().then((querySnapshot) => {
            querySnapshot.forEach((item) => {
                units.push(item.data())
            })
        })
    
        return units
    }

    static async getThreeRecentNews() {
        let articles = []
        
        await firebase.firestore().collection("news").orderBy("date", "desc").limit(3).get().then((querySnapshot) => {
            querySnapshot.forEach((item) => {
                articles.push(item.data())
            })
        })
    
        return articles
    }

    static async getThumbnails(allUnits) {
        let thumbnailObj = {}

        for(const unit of allUnits) {
            let imageRef

            let pathReference = firebase.storage().ref(`images/${unit.unitId}`)

            await pathReference.listAll().then((result) => {
                imageRef = result.items[0]
            })

            if(imageRef) {
                await imageRef.getDownloadURL().then((url) => {
                    thumbnailObj[unit.unitId] = url
                })
            } else {
                thumbnailObj[unit.unitId] = "https://elitescreens.com/images/product_album/no_image.png"
            }
        }

        return thumbnailObj
    }

    static async getSpecificUnit(unitId) {
        let unit

        await firebase.firestore().collection("rentalListings").where("unitId", "==", unitId).get().then((querySnapshot) => {
            querySnapshot.forEach((item) => {
                unit = item.data() // there should only be one!
            })
        })

        return unit
    }
}