import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './components/Home'
import About from './components/About'
import RentalListings from './components/RentalListings'
import UnitDetails from './components/UnitDetails'
import NewsEdu from './components/NewsEdu'
// import AllNews from './components/AllNews'
import RentalApp from './components/RentalApp'
import MaintenanceForm from './components/MaintenanceForm'
import ComplaintForm from './components/ComplaintForm'
import Contact from './components/Contact'
import Submitted from './components/Submitted'
import Admin from './components/Admin/Admin'
import BeforeAfter from './components/BeforeAfter'

import DataContextProvider from './context/DataContext'

//////////////

function App() {

  return (
    <BrowserRouter>

      <Switch>

        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/rental-listings/:unitId" component={UnitDetails} />
        <Route exact path="/rental-application" component={RentalApp} />
        <Route exact path="/maintenance-form" component={MaintenanceForm} />
        <Route exact path="/complaint-form" component={ComplaintForm} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/submitted" component={Submitted} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/before-after" component={BeforeAfter} />

        <DataContextProvider>
          <Route exact path="/rental-listings" component={RentalListings} />
          <Route exact path="/helpful-info" component={NewsEdu} />
          {/* <Route exact path="/news-education/all-news" component={AllNews} /> */}
        </DataContextProvider>

      </Switch>
    </BrowserRouter>
  )
}

export default App;
