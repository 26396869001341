import React, { createContext, Component } from 'react'

import GetData from '../backend/GetData'

//////////////

export const DataContext = createContext()

class DataContextProvider extends Component {

    state = {
        allUnits: null,
        threeRecentNews: [],
    }

    async componentDidMount() {
        this.setState({ 
            allUnits: await GetData.getAllUnits(),
            threeRecentNews: await GetData.getThreeRecentNews()
        })

        this.setState({
            rentalThumbnails: await GetData.getThumbnails(this.state.allUnits)
        })
    }

    render() {
        return(
            <DataContext.Provider value={{...this.state}} >
                {this.props.children}
            </DataContext.Provider>
        )
    }
    
}

export default DataContextProvider
