import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ErrorScreen from "./Error";
import Nav from "./Nav";
import Loader from "react-loader-spinner";

//////////////

export default class MaintenanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isSubmitted: false,
      _isSubmitting: false,
      _error: false,
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  sendReq(e) {
    e.preventDefault();

    this.setState({ _isSubmitting: true });

    const formData = new FormData();
    formData.set("address", this.state.mrAddress);
    formData.set("unit", this.state.mrUnit);
    formData.set("name", this.state.mrName);
    formData.set("contact", this.state.mrContact);
    formData.set("details", this.state.mrDetails);

    fetch("https://allardproperties.ca/_maintenance_req.php", {
      method: "post",
      body: formData,
    })
      .then((res) => {
        console.log(res);
        this.setState({ _isSubmitted: true, _isSubmitting: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ _error: true, _isSubmitting: false });
      });
  }

  removeError() {
    this.setState({ _error: false });
  }

  render() {
    if (this.state._isSubmitted) {
      return <Redirect to="/submitted" />;
    }

    if (this.state._error) {
      return (
        <div>
          <Nav />

          <ErrorScreen clickProp={this.removeError.bind(this)} />
        </div>
      );
    } else {
      return (
        <div>
          <Nav />

          <div className="container">
            <h1 className="page-title">MAINTENANCE REQUEST</h1>
            <div className="page-description">
              <p>
                This maintenance request form should only be used by tenants for
                routine maintenance &amp; repairs.
              </p>
              <br />
              <p>Before sending the form, please check the following:</p>
              <ul>
                <li>- Smell of gas call Enbridge 1-866-763-5427</li>
                <li>
                  - No electricity check electrical panels for tripped breakers
                  (where applicable)
                </li>
                <li>
                  - No heat check furnace switch on wall in furnace room (where
                  applicable)
                </li>
                <li>
                  - Major water leak shut off main water valve. Usually located
                  in basement (where applicable)
                </li>
              </ul>
              <br />
              <p>If there is an EMERGENCY contact the following:</p>
              <ul>
                <li>
                  - Text Service at 613-327-8955. Indicate property address,
                  unit, &amp; emergency.
                </li>
              </ul>
              <br />
              <p>
                <b>
                  ONLY EMERGENCIES will be responded to by text.
                </b>
              </p>
            </div>

            <div className="contact-form-container">
              <form className="contact-form">
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="mr-address">ADDRESS</label>
                    <input
                      type="text"
                      id="mr-address"
                      name="mrAddress"
                      placeholder="enter your address"
                      value={this.state.mrAddress || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="mr-unit">UNIT #</label>
                    <input
                      type="text"
                      id="mr-unit"
                      name="mrUnit"
                      placeholder="enter your unit #"
                      value={this.state.mrUnit || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="mr-name">NAME</label>
                    <input
                      type="text"
                      id="mr-name"
                      name="mrName"
                      placeholder="enter your full name"
                      value={this.state.mrName || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="mr-contact">EMAIL OR PHONE #</label>
                    <input
                      type="text"
                      id="mr-contact"
                      name="mrContact"
                      placeholder="enter your email or phone #"
                      value={this.state.mrContact || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="form-content">
                  <label htmlFor="mr-details">REQUEST DETAILS</label>
                  <textarea
                    type="text"
                    id="mr-details"
                    name="mrDetails"
                    placeholder="enter the details regarding the location and description of the request"
                    value={this.state.mrDetails || ""}
                    onChange={this.handleChange.bind(this)}
                    className="contact-message"
                  />
                </div>
              </form>
            </div>

            <div className="submit-button-container">
              {this.state._isSubmitting ? (
                <div className="submit-button">
                  <Loader
                    type="Oval"
                    color="#ffffff"
                    height={32}
                    width={32}
                    className="contact-submit-loading"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  value="SUBMIT"
                  onClick={this.sendReq.bind(this)}
                  className="submit-button"
                  disabled={
                    !(
                      this.state.mrAddress &&
                      this.state.mrUnit &&
                      this.state.mrName &&
                      this.state.mrContact &&
                      this.state.mrDetails
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
