import React, { Component } from 'react'
import Nav from './Nav'

import { DataContext } from '../context/DataContext'

//////////////

export default class NewsEdu extends Component {

    static contextType = DataContext

    render() {
        return(
            <div>
                <Nav />
                <div className="container">
                    <h1 className="page-title">HELPFUL INFORMATION</h1>
                    <p className="page-description">Useful links for tenants and landlords.</p>
                
                    <div className="info-card">
                        <div className="info-card-content">
                            <div className="info-link-cat-container">
                                <h3 className="info-cat-title">UTILITIES</h3>
                                <div className="info-link-container">
                                    <a href="https://hydroottawa.com" target="_blank" className="info-link">HYDRO</a>
                                    <a href="https://www.enbridgegas.com" target="_blank" className="info-link">ENBRIDGE</a>
                                    <a href="https://www.bell.ca" target="_blank" className="info-link">BELL</a>
                                    <a href="https://www.rogers.com/home" target="_blank" className="info-link">ROGERS</a>
                                </div>
                            </div>
                            <div className="info-link-cat-container">
                                <h3 className="info-cat-title">LOCATIONS</h3>
                                <div className="info-link-container">
                                    <a href="https://ottawa.ca/en" target="_blank" className="info-link">CITY OF OTTAWA</a>
                                    <a href="https://www.pembroke.ca" target="_blank" className="info-link">CITY OF PEMBROKE</a>
                                    <a href="https://www.cityofkingston.ca" target="_blank" className="info-link">CITY OF KINGSTON</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}