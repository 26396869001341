import React, { Component } from 'react'
import HamburgerButton from './MobileMenu/HamburgerButton'
import SideMenu from './MobileMenu/SideMenu'
import DesktopNav from './DesktopNav/DesktopNav'

import './Nav.css'

//////////////

export default class Nav extends Component {

    state = {
        sideMenuOpen: false
    }

    hamburgerClickHandler = () => {
        this.setState((prevState) => {
            return { sideMenuOpen: !prevState.sideMenuOpen }
        })
    }

    render() {

        let sideMenu

        if(this.state.sideMenuOpen) {
            sideMenu = <SideMenu clickHandler={this.hamburgerClickHandler} />
        }

        return(
            <div>
                <div className="hamburger-nav">
                    {sideMenu}
                    <HamburgerButton clickHandler={this.hamburgerClickHandler} />
                </div>
                <div className="desktop-nav">
                    <DesktopNav />
                </div>
            </div>   
        )
        
    }
}