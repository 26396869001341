import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import Nav from "./Nav";
import Loader from "react-loader-spinner";

import { DataContext } from "../context/DataContext";

import * as firebase from "firebase";
import "firebase/storage";

//////////////

export default class RentalListings extends Component {
  static contextType = DataContext;

  state = {};

  render() {
    return (
      <div>
        <Nav />
        <main className="container">
          <h1 className="page-title">Rental Listings</h1>
          <p className="page-description">
            This is a list of all our rental properties. Click on a property to
            see more details.
          </p>

          {/* 
          If allUnits is null display loading indicator,
          else display the list of units
          */}

          {this.context.allUnits === null ? (
            <Loader
              type="Oval"
              color="#ffbc28"
              height={56}
              width={56}
              className="page-loading"
            />
          ) : (
            <ul className="rentals-list">
              {this.context.allUnits.map((item) => {
                let thumbnailSrc = "loading";
                if (this.context.rentalThumbnails) {
                  thumbnailSrc = this.context.rentalThumbnails[item.unitId];
                } else {
                  // TODO: loading image component
                }

                return (
                  <Link
                    to={`/rental-listings/${item.unitId}`}
                    className="rental-link"
                  >
                    <li className="rental-container">
                      <div className="rental-thumbnail-container">
                        {/* Loading thumbnail */}
                        {thumbnailSrc === "loading" ? (
                          <div className="thumbnail">
                            <div className="thumbnail-loading-container">
                              <Loader
                                type="Oval"
                                color="#ffbc28"
                                height={40}
                                width={40}
                                className="thumbnail-loading"
                              />
                            </div>
                          </div>
                        ) : !item.rented ? (
                          <img
                            src={thumbnailSrc}
                            alt=""
                            className="thumbnail"
                          />
                        ) : (
                          <img
                            src={thumbnailSrc}
                            alt=""
                            className="thumbnail thumbnail-rented"
                          />
                        )}
                      </div>

                      <div className="rental-text-container">
                        {!item.rented ? (
                          <div className="address-price-container">
                            <h2 className="rental-address grey-text">
                              {item.address}
                            </h2>
                            <h2 className="rental-price grey-text">
                              {item.price}
                            </h2>
                          </div>
                        ) : (
                          <div>
                            <h2 className="rental-address rental-address-rented grey-text">
                              {item.address}
                            </h2>
                            <p className="rental-rented-tag">(RENTED)</p>
                          </div>
                        )}

                        <p className="rental-desc grey-text">{item.desc}</p>

                        <ul className="tags-container">
                          <li className="tag grey-text">
                            {item.tags.neighbourhood}
                          </li>
                          <li className="tag grey-text">
                            Beds: {item.tags.bed}
                          </li>
                          <li className="tag grey-text">
                            Baths: {item.tags.bath}
                          </li>
                        </ul>
                      </div>

                      <div className="arrow-icon-container">
                        <FaChevronRight className="chevron-right" />
                      </div>
                    </li>
                  </Link>
                );
              })}
            </ul>
          )}
        </main>
      </div>
    );
  }
}
