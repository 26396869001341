import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Nav from './Nav'

import white_logo from '../img/white_logo.png'

//////////////

export default class Home extends Component {

    render() {
        return(
            <div>

                <Nav />

                 <div className="container">

                    <div className="home-content">

                        <img src={white_logo} alt="white logo" className="white-logo" />

                        <h1 className="welcome-title">Connecting People with Properties.</h1>

                        <p className="welcome-message">Welcome to Allard Properties Inc. We are a residential
                                                        management company providing quality, clean, and safe homes &amp;
                                                        apartments for qualified tenants in The Ottawa Region. </p>

                        <div className="home-buttons-container">
                            <Link to="/rental-application" className="button">RENTAL APPLICATION</Link>
                            <Link to="/maintenance-form" className="button">MAINTENANCE REQUEST</Link>
                        </div>
                        
                    </div>

                </div>
                
            </div>
           
        )
    }

}