import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaCaretDown } from 'react-icons/fa'

import nav_logo from '../../img/nav_logo_desktop.png'

import './DesktopNav.css'

//////////////

const DesktopNav = props => (
    <div>
        <nav className="desktop-nav-container">
            <img src={nav_logo} alt="header logo" className="desktop-nav-logo" />
            <ul className="desktop-nav-list">
                <li>
                    <NavLink to="/" onClick={props.clickHandler} className="desktop-nav-link" exact activeClassName="desktop-nav-link-active">HOME</NavLink>
                </li>
                <li>
                    <NavLink to="/about" onClick={props.clickHandler} className="desktop-nav-link" activeClassName="desktop-nav-link-active">ABOUT</NavLink>
                </li>
                <li>
                    <NavLink to="/rental-listings" onClick={props.clickHandler} className="desktop-nav-link" activeClassName="desktop-nav-link-active">RENTALS</NavLink>
                </li>
                <li>
                    <NavLink to="/before-after" onClick={props.clickHandler} className="desktop-nav-link" activeClassName="desktop-nav-link-active">PROPERTY IMPROVEMENTS</NavLink>
                </li>
                <div className="desktop-dropdown">
                    <button className="desktop-forms-button">FORMS <FaCaretDown className="caret-down" /></button>
                    <div className="desktop-dropdown-child">
                        <NavLink to="/rental-application" onClick={props.clickHandler} className="desktop-dropdown-link grey-text mr-link" activeClassName="desktop-nav-link-active">RENTAL APPLICATION</NavLink>
                        <NavLink to="/maintenance-form" onClick={props.clickHandler} className="desktop-dropdown-link grey-text mr-link" activeClassName="desktop-nav-link-active">MAINTENANCE REQUEST</NavLink>
                        <NavLink to="/complaint-form" onClick={props.clickHandler} className="desktop-dropdown-link grey-text mr-link" activeClassName="desktop-nav-link-active">TENANT COMPLAINT FORM</NavLink>
                    </div>
                </div>
                <li>
                    <NavLink to="/helpful-info" onClick={props.clickHandler} className="desktop-nav-link" activeClassName="desktop-nav-link-active">HELPFUL INFORMATION</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" onClick={props.clickHandler} className="desktop-nav-link" activeClassName="desktop-nav-link-active">CONTACT</NavLink>
                </li>
            </ul>
        </nav>
    </div> 
)

export default DesktopNav