import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Nav from "./Nav";
import Loader from "react-loader-spinner";

import GetData from "../backend/GetData";

import * as firebase from "firebase";
import "firebase/storage";

//////////////

export default class ErrorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="submit-container">
          <h1 className="submit-header">Oh no!</h1>
          <p className="submit-message">
            There was an error processing the request. Please email
            derek.a@allardproperties.ca if the problem persists.
          </p>
          <input
            value="BACK"
            type="submit"
            className="button submitted-button"
            onClick={this.props.clickProp}
          />
        </div>
      </div>
    );
  }
}
