import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ErrorScreen from "./Error";
import Nav from "./Nav";
import Loader from "react-loader-spinner";

//////////////

export default class ComplaintForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isSubmitted: false,
      _isSubmitting: false,
      _error: false,
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  sendReq(e) {
    e.preventDefault();

    this.setState({ _isSubmitting: true });

    const formData = new FormData();
    formData.set("address", this.state.mrAddress);
    formData.set("unit", this.state.mrUnit);
    formData.set("name", this.state.mrName);
    formData.set("contact", this.state.mrContact);
    formData.set("date", this.state.mrDate);
    formData.set("details", this.state.mrDetails);

    fetch("https://allardproperties.ca/_complaint.php", {
      method: "post",
      body: formData,
    })
      .then((res) => {
        console.log(res);
        this.setState({ _isSubmitted: true, _isSubmitting: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ _error: true, _isSubmitting: false });
      });
  }

  removeError() {
    this.setState({ _error: false });
  }

  render() {
    if (this.state._isSubmitted) {
      return <Redirect to="/submitted" />;
    }

    if (this.state._error) {
      return (
        <div>
          <Nav />

          <ErrorScreen clickProp={this.removeError.bind(this)} />
        </div>
      );
    } else {
      return (
        <div>
          <Nav />

          <div className="container">
            <h1 className="page-title">TENANT COMPLAINT FORM</h1>
            <div className="page-description">
              <p>Tenant complaint form regarding other tenants/occupants.</p>
              <br />
              <p><strong>Important notice:</strong></p>
              <p>Please note this complaint form being provided
                to your landlord is subject to disclosure pursuant to the Ontario Evidence Act
                and the Rules of Procedures pursuant to Rule 19 of the Landlord and Tenant Board
                for any contested evictions.</p>
            </div>

            <div className="contact-form-container">
              <form className="contact-form">
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="mr-address">ADDRESS</label>
                    <input
                      type="text"
                      id="mr-address"
                      name="mrAddress"
                      placeholder="enter your address"
                      value={this.state.mrAddress || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="mr-unit">UNIT #</label>
                    <input
                      type="text"
                      id="mr-unit"
                      name="mrUnit"
                      placeholder="enter your unit #"
                      value={this.state.mrUnit || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="mr-name">NAME</label>
                    <input
                      type="text"
                      id="mr-name"
                      name="mrName"
                      placeholder="enter your full name"
                      value={this.state.mrName || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="mr-contact">EMAIL OR PHONE #</label>
                    <input
                      type="text"
                      id="mr-contact"
                      name="mrContact"
                      placeholder="enter your email or phone #"
                      value={this.state.mrContact || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="mr-date">TIME & DATE OF INCIDENT</label>
                    <input
                      type="text"
                      id="mr-date"
                      name="mrDate"
                      placeholder="enter time & date"
                      value={this.state.mrDate || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-left"></div>
                </div>
                <div className="form-content">
                  <label htmlFor="mr-details">COMPLAINT DETAILS</label>
                  <textarea
                    type="text"
                    id="mr-details"
                    name="mrDetails"
                    placeholder="Please provide detailed explanation of the incident."
                    value={this.state.mrDetails || ""}
                    onChange={this.handleChange.bind(this)}
                    className="contact-message"
                  />
                </div>
              </form>
            </div>

            <div className="submit-button-container">
              {this.state._isSubmitting ? (
                <div className="submit-button">
                  <Loader
                    type="Oval"
                    color="#ffffff"
                    height={32}
                    width={32}
                    className="contact-submit-loading"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  value="SUBMIT"
                  onClick={this.sendReq.bind(this)}
                  className="submit-button"
                  disabled={
                    !(
                      this.state.mrAddress &&
                      this.state.mrUnit &&
                      this.state.mrName &&
                      this.state.mrContact &&
                      this.state.mrDate &&
                      this.state.mrDetails
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
