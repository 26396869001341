import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import Nav from "./Nav";

import b1 from "../img/beforeafter/1_1.JPG";
import b2 from "../img/beforeafter/1_2.JPG";
import a1 from "../img/beforeafter/1_1a.JPG";
import a2 from "../img/beforeafter/1_2a.JPG";
import b3 from "../img/beforeafter/2_1.JPG";
import b4 from "../img/beforeafter/2_2.JPG";
import a3 from "../img/beforeafter/2_1a.JPG";
import a4 from "../img/beforeafter/2_2a.JPG";

import b5 from "../img/beforeafter/3_1.jpg";
import a5 from "../img/beforeafter/3_1a.jpg";
import b6 from "../img/beforeafter/3_2.jpg";
import a6 from "../img/beforeafter/3_2a.jpg";
import b7 from "../img/beforeafter/3_3.jpg";
import a7 from "../img/beforeafter/3_3a.jpg";

//////////////

export default class BeforeAfter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrls: [
        {
          original: b1,
          thumbnail: b1,
        },
        {
          original: a1,
          thumbnail: a1,
        },
        {
          original: b2,
          thumbnail: b2,
        },
        {
          original: a2,
          thumbnail: a2,
        },
        {
          original: b3,
          thumbnail: b3,
        },
        {
          original: a3,
          thumbnail: a3,
        },
        {
          original: b4,
          thumbnail: b4,
        },
        {
          original: a4,
          thumbnail: a4,
        },
        {
          original: b5,
          thumbnail: b5,
        },
        {
          original: a5,
          thumbnail: a5,
        },
        {
          original: b6,
          thumbnail: b6,
        },
        {
          original: a6,
          thumbnail: a6,
        },
        {
          original: b7,
          thumbnail: b7,
        },
        {
          original: a7,
          thumbnail: a7,
        },
      ],
    };
  }

  async buildImageList() {
    let imageUrls = [];

    let imageObj = {
      original: "https://elitescreens.com/images/product_album/no_image.png",
      thumbnail: "https://elitescreens.com/images/product_album/no_image.png",
    };

    this.setState({ imageUrls });
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="piss-off">
          <div className="before-after-container">
            <div className="b4-header">
              <h1 className="unit-address grey-text">
                BEFORE AND AFTER PHOTOS
              </h1>
            </div>

            <div className="b4-gall-container">
              <ImageGallery items={this.state.imageUrls} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
