import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Nav from './Nav'

import about_image from '../img/about.jpg'

/////////////

export default class About extends Component {

    render() {
        return(
            <div>
                <Nav />
                <main className="container">
                    <h1 className="page-title-no-desc">About Us</h1>
                    <div className="about-container">
                        <div className="about-card">
                            <div className="about-card-content">
                                <img src={about_image} alt="about image" className="about-img" />
                                <div className="about-text">
                                    <p className="grey-text">Allard Properties Inc. is owned by Derek Allard.  
                                                            Our portfolio consists of properties throughout The Ottawa &amp; Ottawa Valley Region.  
                                                            Please view our RENTALS section for available rentals.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Link to="/rental-listings" className="about-button">RENTALS</Link>
                        </div>
                    </div>
                </main>
            </div>
        )
    }

}