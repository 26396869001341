import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

import './SideMenu.css'

//////////////

const SideMenu = props => (
    <div>
        <nav className="mobile-nav-container">
            <ul className="mobile-nav-list">
                <li className="exit-button-container">
                    <button onClick={props.clickHandler} className="exit-button"><FaTimes className="fa-times" /></button>
                </li>
                <li>
                    <NavLink to="/" onClick={props.clickHandler} className="mobile-nav-link" exact activeClassName="mobile-nav-link-active">HOME</NavLink>
                </li>
                <li>
                    <NavLink to="/about" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">ABOUT</NavLink>
                </li>
                <li>
                    <NavLink to="/rental-listings" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">RENTALS</NavLink>
                </li>
                <li>
                    <NavLink to="/before-after" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">PROPERTY IMPROVEMENTS</NavLink>
                </li>
                <li>
                    <NavLink to="/helpful-info" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">HELPFUL INFORMATION</NavLink>
                </li>
                <li>
                    <NavLink to="/rental-application" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">RENTAL APPLICATION</NavLink>
                </li>
                <li>
                    <NavLink to="/maintenance-form" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">MAINTENANCE REQUEST</NavLink>
                </li>
                <li>
                    <NavLink to="/complaint-form" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">TENANT COMPLAINT FORM</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" onClick={props.clickHandler} className="mobile-nav-link" activeClassName="mobile-nav-link-active">CONTACT</NavLink>
                </li>
            </ul>
        </nav>
    </div> 
)

export default SideMenu