import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Nav from "./Nav";
import Loader from "react-loader-spinner";

import GetData from "../backend/GetData";

import * as firebase from "firebase";
import "firebase/storage";

//////////////

export default class UnitDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isLoading: true,
      unitData: {},
      imageUrls: [],
    };
  }

  async componentDidMount() {
    await this.setState({
      unitData: await GetData.getSpecificUnit(this.props.match.params.unitId),
    });
    await this.buildImageList();
    await this.setState({ _isLoading: false });
  }

  async buildImageList() {
    let imageRefs = [];
    let imageUrls = [];
    let pathReference = firebase
      .storage()
      .ref(`images/${this.props.match.params.unitId}`);

    // puts a list of all the image references in an array
    await pathReference.listAll().then((result) => {
      result.items.forEach((imageRef) => {
        imageRefs.push(imageRef);
      });
    });

    // using a for of loop (forEach doesn't work) to put all of the image download url objects in an array
    if (imageRefs.length > 0) {
      for (let item of imageRefs) {
        let imageObj = {
          original: "",
          thumbnail: "",
        };

        // react-image-gallery takes an array of objects with "original" and "thumbnail" properties
        await item.getDownloadURL().then((url) => {
          imageObj.original = url;
          imageObj.thumbnail = url;
        });

        imageUrls.push(imageObj);
      }
    } else {
      let imageObj = {
        original: "https://elitescreens.com/images/product_album/no_image.png",
        thumbnail: "https://elitescreens.com/images/product_album/no_image.png",
      };

      imageUrls.push(imageObj);
    }

    this.setState({ imageUrls });
  }

  render() {
    return this.state._isLoading ? (
      <div>
        <Nav />
        <div className="page-loading-container">
          <Loader
            type="Oval"
            color="#ffbc28"
            height={56}
            width={56}
            className="page-loading"
          />
        </div>
      </div>
    ) : (
      <div>
        <Nav />
        <div className="not-container">
          <div className="unit-content">
            {!this.state.unitData.rented ? (
              <div>
                <h1 className="unit-address grey-text">
                  {this.state.unitData.address}
                </h1>
                <p className="unit-price grey-text">
                  {this.state.unitData.price}
                </p>
              </div>
            ) : (
              <div className="unit-address-rented-container">
                <h1 className="unit-address rental-address-rented grey-text">
                  {this.state.unitData.address}
                </h1>
                <p className="rental-rented-tag">(RENTED)</p>
              </div>
            )}

            <div className="image-gallery-container">
              <ImageGallery items={this.state.imageUrls} />
            </div>

            <div className="unit-tags-container">
              <p className="unit-tag grey-text">
                {this.state.unitData.tags.neighbourhood}
              </p>
              <p className="unit-tag grey-text">
                Beds: {this.state.unitData.tags.bed}
              </p>
              <p className="unit-tag grey-text">
                Baths: {this.state.unitData.tags.bath}
              </p>
            </div>

            <h2 className="unit-desc-title grey-text">DESCRIPTION</h2>
            <p className="unit-desc grey-text">{this.state.unitData.desc}</p>

            <h2 className="unit-info-title grey-text">INFO</h2>

            <div className="unit-info-container">
              <ul className="grey-text">
                <li>
                  <p className="unit-info-item-title">UNIT TYPE: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.type}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">UNIT SIZE: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.unitSize}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">AGREEMENT TYPE: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.agreementType}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">MOVE IN DATE: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.moveDate}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">PARKING SPACES: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.parking}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">PET FRIENDLY: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.petFriendly}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">
                    PERSONAL OUTDOOR SPACE:{" "}
                  </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.outdoorSpace}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">SMOKING PERMITTED: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.smoking}
                  </p>
                </li>
              </ul>
              <br />
              <ul className="grey-text">
                <li>
                  <p className="unit-info-item-title">UTILITIES: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.utilities}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">WIFI: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.wifi}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">FURNISHED: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.furnished}
                  </p>
                </li>
                <li>
                  <p className="unit-info-item-title">AC: </p>
                  <p className="unit-info-item">
                    {this.state.unitData.info.ac}
                  </p>
                </li>
              </ul>
            </div>

            <div className="unit-rental-button-container">
              <RentalAppButton rented={this.state.unitData.rented} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class RentalAppButton extends Component {
  // using the "disabled" attribute isn't possible with <Link />, so we use this component
  render() {
    if (this.props.rented) {
      return <span className="unit-rental-button disabled">UNAVAILABLE</span>;
    }
    return (
      <Link to="/rental-application" className="unit-rental-button">
        RENTAL APPLICATION
      </Link>
    );
  }
}
