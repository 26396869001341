import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Nav from "./Nav";
import Loader from "react-loader-spinner";
import ErrorScreen from "./Error";

//////////////

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isSubmitted: false,
      _isSubmitting: false,
      _error: false,
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  sendMsg(e) {
    e.preventDefault();

    this.setState({ _isSubmitting: true });

    const formData = new FormData();
    formData.set("name", this.state.contactName);
    formData.set("email", this.state.contactEmail);
    formData.set("phone", this.state.contactPhone);
    formData.set("subject", this.state.contactSubject);
    formData.set("message", this.state.contactMessage);

    fetch("https://allardproperties.ca/_contact.php", {
      method: "post",
      body: formData,
    })
      .then((res) => {
        console.log(res);
        this.setState({ _isSubmitted: true, _isSubmitting: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ _error: true, _isSubmitting: false });
      });
  }

  removeError() {
    this.setState({ _error: false });
  }

  render() {
    if (this.state._isSubmitted) {
      return <Redirect to="/submitted" />;
    }

    if (this.state._error) {
      return (
        <div>
          <Nav />

          <ErrorScreen clickProp={this.removeError.bind(this)} />
        </div>
      );
    } else {
      return (
        <div>
          <Nav />

          <div className="container">
            <h1 className="page-title">CONTACT</h1>
            <div className="page-description">
              <p>
                Contact us with any general questions you may have about rentals,
                services, applications, etc.
              </p>
              <br />
              <p><strong>Allard Properties Inc.</strong></p>
              <p>Suite 307</p>
              <p>  11-300 Earl Grey Dr.</p>
              <p>Kanata, ON</p>
              <p>K2T 1C1</p>
              <br/>
              <p>rentals@allardproperties.ca</p>
            </div>

            <div className="contact-form-container">
              <form className="contact-form">
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="contact-name">NAME</label>
                    <input
                      type="text"
                      id="contact-name"
                      name="contactName"
                      placeholder="enter your name"
                      value={this.state.contactName || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="contact-email">EMAIL</label>
                    <input
                      type="text"
                      id="contact-email"
                      name="contactEmail"
                      placeholder="enter your email"
                      value={this.state.contactEmail || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="contact-row">
                  <div className="form-content contact-item-left">
                    <label htmlFor="contact-phone">PHONE #</label>
                    <input
                      type="text"
                      id="contact-phone"
                      name="contactPhone"
                      placeholder="enter your phone #"
                      value={this.state.contactPhone || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="form-content contact-item-right">
                    <label htmlFor="contact-subject">SUBJECT</label>
                    <input
                      type="text"
                      id="contact-subject"
                      name="contactSubject"
                      placeholder="enter the subject"
                      value={this.state.contactSubject || ""}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="form-content">
                  <label htmlFor="contact-message">MESSAGE</label>
                  <textarea
                    type="text"
                    id="contact-message"
                    name="contactMessage"
                    placeholder="enter the message"
                    value={this.state.contactMessage || ""}
                    onChange={this.handleChange.bind(this)}
                    className="contact-message"
                  />
                </div>
              </form>
            </div>

            <div className="submit-button-container">
              {this.state._isSubmitting ? (
                <div className="submit-button">
                  <Loader
                    type="Oval"
                    color="#ffffff"
                    height={32}
                    width={32}
                    className="contact-submit-loading"
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  value="SUBMIT"
                  onClick={this.sendMsg.bind(this)}
                  className="submit-button"
                  disabled={
                    !(
                      this.state.contactName &&
                      (this.state.contactEmail || this.state.contactPhone) &&
                      this.state.contactSubject &&
                      this.state.contactMessage
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
